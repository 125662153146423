// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blog-2020-12-17-a-simple-explanation-of-the-react-use-state-hook-index-mdx": () => import("./../../../src/pages/blog/2020-12-17-a-simple-explanation-of-the-react-useState-hook/index.mdx" /* webpackChunkName: "component---src-pages-blog-2020-12-17-a-simple-explanation-of-the-react-use-state-hook-index-mdx" */),
  "component---src-pages-blog-2020-12-24-the-best-programming-language-in-2021-index-mdx": () => import("./../../../src/pages/blog/2020-12-24-the-best-programming-language-in-2021/index.mdx" /* webpackChunkName: "component---src-pages-blog-2020-12-24-the-best-programming-language-in-2021-index-mdx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reading-list-mdx": () => import("./../../../src/pages/reading-list.mdx" /* webpackChunkName: "component---src-pages-reading-list-mdx" */)
}

